<template>
  <div class="pageination-align">
    <div class="pageination" v-if="pageinationTotal">
      <a href="javascript:;" @click="pageUp(0)" class="pagination-page p-btn" :class="startDisabled ? 'disabled' : ''">首页</a>
      <a href="javascript:;" @click="pageUp(1)" class="pagination-page" :class="startDisabled ? 'disabled' : ''"><<</a>
      <a href="javascript:;" class="pagination-page" :class="item == pageinationCurrentPage ? 'pagination-page-active' : ''" v-for="(item, index) in pageinationLength" :key="index" @click="jump(item)">
        {{ item }}
      </a>
      <a href="javascript:;" @click="pageDown(1)" class="pagination-page" :class="endDisabled ? 'disabled' : ''">>></a>
      <a href="javascript:;" @click="pageDown(0)" class="pagination-page pagination-page-right p-btn" :class="endDisabled ? 'disabled' : ''">末页</a>
      <!-- <div>共 {{ pageinationPage }} 页 &nbsp;&nbsp;共 {{ pageinationTotal }} 条记录</div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  //props-接收父组件传过来的分页参数
  props: ['total', 'size', 'page', 'changge', 'isUrl'],
  data() {
    return {
      pageinationTotal: this.total, //总条目数
      pageinationSize: this.size ? this.size : 10, //每页显示条目个数
      pageinationLength: [],
      pageinationCurrentPage: this.page ? this.page : 1, //当前页数默认1
      pageinationPage: 0, //可分页数
      startDisabled: true, //是否可以点击首页上一页
      endDisabled: true, //是否可以点击尾页下一页
      pageChangge: this.changge, //修改方法
      pageIsUrl: this.isUrl ? true : false, //是否开启修改url
    };
  },
  created() {
    this.pageCurrentChange();
  },
  watch: {
    total: function(val, oldVal) {
      this.pageinationTotal = val;
      this.pagers();
    },
    page: function(val, oldVal) {
      this.pageinationCurrentPage = val;
      this.pagers();
    },
  },
  methods: {
    jump(item) {
      this.pageinationCurrentPage = item;
      this.pagers();
      this.pageChangge(this.pageinationCurrentPage);
    }, //跳转页码
    pagers() {
      //可分页数
      this.pageinationPage = Math.ceil(this.pageinationTotal / this.pageinationSize);
      //url修改
      if (this.pageIsUrl) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            page: this.pageinationCurrentPage,
          },
        });
      }
      //是否可以点击上一页首页
      this.startDisabled = this.pageinationCurrentPage != 1 ? false : true;
      //是否可以点击下一页尾页

      this.endDisabled = this.pageinationCurrentPage != this.pageinationPage ? false : true;
      if (this.pageinationPage == 0) this.endDisabled = true;
      //重置
      this.pageinationLength = [];
      //开始页码1
      let start = this.pageinationCurrentPage - 4 > 1 ? this.pageinationCurrentPage - 4 : 1;
      //当前页码减去开始页码得到差
      let interval = this.pageinationCurrentPage - start;
      //最多9个页码，总页码减去interval 得到end要显示的数量+
      let end = 9 - interval < this.pageinationPage ? 9 - interval : this.pageinationPage;
      //最末页码减开始页码小于8
      if (end - start != 8) {
        //最末页码加上与不足9页的数量，数量不得大于总页数
        end = end + (8 - (end - start)) < this.pageinationPage ? end + (8 - (end - start)) : this.pageinationPage;
        //最末页码加上但是还不够9页，进行开始页码追加，开始页码不得小于1
        if (end - start != 8) {
          start = end - 8 > 1 ? end - 8 : 1;
        }
      }
      for (let i = start; i <= end; i++) {
        this.pageinationLength.push(i);
      }
    }, //计算分页显示的数字
    pageUp(state) {
      if (this.startDisabled) return;
      if (this.pageinationCurrentPage - 1 != 0 && state == 1) {
        this.jump(this.pageinationCurrentPage - 1);
      } else {
        this.jump(1);
      }
    }, //上一页跟首页 state=1是上一页 state=0是首页
    pageDown(state) {
      if (this.endDisabled) return;
      if (this.pageinationCurrentPage + 1 != this.pageinationPage && state == 1) {
        this.jump(this.pageinationCurrentPage + 1);
      } else {
        this.jump(this.pageinationPage);
      }
    }, // state=1是下一页 state=0是尾页
    pageCurrentChange() {
      this.pageChangge(this.pageinationCurrentPage);
      this.pagers();
    },
  },
};
</script>

<style>
.pageination-align {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 0.16rem;
  color: #515861;
}
.pagination-page {
  margin: 0 0.1rem;
  cursor: pointer;
  color: #fff;
}
.pagination-page:hover {
  color: #e2c188;
}
.pagination-page-active {
  color: #515861;
  font-weight: bold;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.2;
}
.p-btn {
  color: #fff;
  padding: 0 0.1rem;
  background: transparent !important;
}
.p-btn:hover {
  background: #767676 !important;
  border-radius: 0.3rem;
  color: #fff;
}
</style>
